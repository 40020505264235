import FileTree from "../../data/file-tree";
function arrayToObject(array) {
  const result = {};
  for (let element of array) {
    const children = array
      .filter((item) => item.parentId == element.id)
      .map((item) => item.id);
    result[element.id] = { ...element, children };
  }

  const children = array
    .filter((item) => !item.parentId)
    .map((item) => item.id);

  result[0] = { children };
  return result;
}

export default function () {
  return arrayToObject(FileTree);
  // $api.fs.folders.get().sort('createdAt,desc').then((response)=>{
  //   return arrayToObject(response.data.data);
  // })
}
